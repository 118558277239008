
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class CarouselReferenceCard extends Vue {
        // Properties
        @Prop() private readonly refereeImage!: string;

        @Prop() private readonly paragraph1!: string;
        @Prop() private readonly paragraph2!: string;
        @Prop() private readonly paragraph3!: string;

        @Prop() private readonly refereeName!: string;
        @Prop() private readonly refereeRole!: string;
        @Prop() private readonly date!: string;
        @Prop() private readonly cardNumber!: string;

        // Data function
        public data() {
            return {
            };
        }
    }
