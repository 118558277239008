
    import CarouselReferenceCard from "@/components/carousel_cards/carousel_reference_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselReferenceCard
        },
    })

    // *****************************************************************************************************************
    export default class References2018To2019 extends Vue {
        // Data function
        public data() {
            return {
                alistairRuffettImagePathAndName:
                    require("@/assets/images/alistair_ruffett.png"),

                andreaNagelImagePathAndName:
                    require("@/assets/images/andrea_nagel.png"),

                binLinImagePathAndName:
                    require("@/assets/images/bin_lin.png"),

                jamesHennesseyImagePathAndName:
                    require("@/assets/images/james_hennessey.png"),

                mathiasMouchardImagePathAndName:
                    require("@/assets/images/mathias_mouchard.png"),

                pedroAlvesImagePathAndName:
                    require("@/assets/images/pedro_alves.png"),

                referenceIconPathAndName:
                    require("@/assets/images/icon_reference.png"),

                robinWilsonImagePathAndName:
                    require("@/assets/images/robin_wilson.png"),

                santiagoDehazaImagePathAndName:
                    require("@/assets/images/santiago_dehaza.png"),

                sinanGucluImagePathAndName:
                    require("@/assets/images/sinan_guclu.png"),

                theodoreCruickshankImagePathAndName:
                    require("@/assets/images/theodore_cruickshank.png"),

                tomCrowderImagePathAndName:
                    require("@/assets/images/tom_crowder.png"),

                richardColemanImagePathAndName:
                    require("@/assets/images/richard_coleman.png"),

                ruslanZaharovImagePathAndName:
                    require("@/assets/images/ruslan_zaharov.png"),

                vincentSavoryImagePathAndName:
                    require("@/assets/images/vincent_savory.png"),
            };
        }
    }
