
    import CarouselReferenceCard from "@/components/carousel_cards/carousel_reference_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselReferenceCard
        },
    })

    // *****************************************************************************************************************
    export default class References2001To2013 extends Vue {
        // Data function
        public data() {
            return {
                andrewWallenPathAndName:
                    require("@/assets/images/andrew_wallen.png"),

                aamarRanaImagePathAndName:
                    require("@/assets/images/aamar_rana.png"),

                amritBajwaPathAndName:
                    require("@/assets/images/amrit_bajwa.png"),

                christopherHardwickImagePathAndName:
                    require("@/assets/images/christopher_hardwick.png"),

                danieleDeBlasioImagePathAndName:
                    require("@/assets/images/daniele_de_blasio.png"),

                emrahElmasliImagePathAndName:
                    require("@/assets/images/emrah_elmasli.png"),

                gavinWhitlockImagePathAndName:
                    require("@/assets/images/gavin_whitlock.png"),

                jamieDurrantImagePathAndName:
                    require("@/assets/images/jamie_durrant.png"),

                jonCreightonImagePathAndName:
                    require("@/assets/images/jon_creighton.png"),

                paulEvansImagePathAndName:
                    require("@/assets/images/paul_evans.png"),

                referenceIconPathAndName:
                    require("@/assets/images/icon_reference.png"),

                sylvainCornillonImagePathAndName:
                    require("@/assets/images/sylvain_cornillon.png"),

                tomKeresztesImagePathAndName:
                    require("@/assets/images/tom_keresztes.png"),

                tasosTzimorotasImagePathAndName:
                    require("@/assets/images/tasos_tzimorotas.png"),
            };
        }
    }
