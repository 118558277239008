
    import CarouselReferenceCard from "@/components/carousel_cards/carousel_reference_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselReferenceCard
        },
    })

    // *****************************************************************************************************************
    export default class References2014To2017 extends Vue {
        // Data function
        public data() {
            return {
                alejandroSimonImagePathAndName:
                    require("@/assets/images/alejandro_simon.png"),

                federicoSutterImagePathAndName:
                    require("@/assets/images/federico_sutter.png"),

                magdalenaKonkiewiczImagePathAndName:
                    require("@/assets/images/magdalena_konkiewicz.png"),

                referenceIconPathAndName:
                    require("@/assets/images/icon_reference.png"),

                ricardoPadrelaImagePathAndName:
                    require("@/assets/images/ricardo_padrela.png"),

                richardCrickImagePathAndName:
                    require("@/assets/images/richard_crick.png"),

                ryanCourtierImagePathAndName:
                    require("@/assets/images/ryan_courtier.png"),

                ruiCostaImagePathAndName:
                    require("@/assets/images/rui_costa.png"),

                tomAvisImagePathAndName:
                    require("@/assets/images/tom_avis.png"),

                vincentNathanImagePathAndName:
                    require("@/assets/images/vincent_nathan.png"),

                yasanthaMeegodageImagePathAndName:
                    require("@/assets/images/yasantha_meegodage.png"),
            };
        }
    }
