import { render, staticRenderFns } from "./references_2018_to_2019.vue?vue&type=template&id=298b7696&scoped=true&lang=pug"
import script from "./references_2018_to_2019.vue?vue&type=script&lang=ts"
export * from "./references_2018_to_2019.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "298b7696",
  null
  
)

export default component.exports