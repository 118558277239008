
    import References2001To2013 from "@/components/references/references_2001_to_2013.vue";
    import References2014To2017 from "@/components/references/references_2014_to_2017.vue";
    import References2018To2019 from "@/components/references/references_2018_to_2019.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            References2001To2013,
            References2014To2017,
            References2018To2019
        },
    })

    // *****************************************************************************************************************
    export default class AboutMyReferences extends Vue {
        // Data function
        public data() {
            return {
                referenceIconPathAndName:
                    require("@/assets/images/icon_reference.png"),
            };
        }
    }
